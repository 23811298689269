<template>
    <transition name="slide-fade">
        <div class="error-message" v-if="msg">
            <span class="badge badge-danger  badge-pill" v-html="msg"></span>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'ErrorMessage',
        props: {
            msg: String
        }
    }
</script>

<style scoped lang="scss">
    .badge {
    font-weight: normal;
    }
</style>
